:root {
	--putih: #fff;
	--birumuda: #329dff;
	--birutua: #223448;
	--birutua2: #1A2837;
	--kuning-hijau: #E0E411;
	--abu-abu: #888;
	--abu-abu2: #bbb;
	--abu-abu3: #eee;
	--hijau-muda: #00B319;
	--hitam: #333;
	--salmon: #FF5652;
	/* splashscreen */
	--ungu: #9F4781;
	--kuning: #FFAB28;
	--font-kecil: 1em;
	--font-sedang: 1.4em;
}

hr{ margin: .5em 0;  }

#app{ background: var(--birutua); }
p{ line-height: 1.3em }

.fix-btn-bottom {
	width: 100%;
	border: none;
	border-radius: 5px 5px 0 0;
	padding: .75em;
	color: white;
	position: fixed;
	bottom: 0;
}

/* === Menu User Report */
.progress-success{background:#00B319}
.progress-warning{background:#E0E411}
.progress-danger{background:#DE5753}

/* === Bottom Nav === */
	nav{
		position: fixed;
		bottom: 0;
		width: 100%;
		background-color: #fff;
		display: inline-block;
		border-radius: 20px 20px 0 0;
	}
	nav .menus-wrapper{
		width: 90%;
		margin: 0 auto;
		display: block;
		height: 60px;
	}
	nav .menus-wrapper a{
		display: inline-block;
		padding: 0 1.5em; 
	}
	.menu-wrapper{
		padding: 7px 5px;
		text-align: center;
		display: inline-block;
	}
	.menu-wrapper p{
		font-weight: bold;
		font-size: 12px;
		color: #333;
	}
	/*menu-active*/
	.active_ p{
		color: var(--birumuda);
	}
	.active_ path{
		fill: var(--birumuda);
	}